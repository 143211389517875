
<script>
export default {
  created() {
    this.$store
      .dispatch('logout')
      .then(() => {
        // this.$router.push({ name: 'Login' });
        window.location.href = 'login';
      })
      .catch((error) => {
        console.error(error);
      });
  },
};
</script>
